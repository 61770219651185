import React from "react";
import Layout from "../components/Layout";
import Navbar from "../components/Navbar";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import course1 from "../assets/images/courses/preschool.jpg";
import course2 from "../assets/images/courses/precshool-curr.jpg";
import course3 from "../assets/images/courses/jolly.jpg";
import course4 from "../assets/images/courses/edusmarty.jpg";
import course5 from "../assets/images/courses/hwt.jpg";
import course6 from "../assets/images/courses/ntt2-01.jpg";
import course7 from "../assets/images/courses/one.jpg";
const CoursesPage = () => {
  return (
    <Layout pageTitle="KWEC | Courses">
      <Navbar />
      <PageHeader title="Courses" />
      <section className="course-one course-page">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="item">
              <div className="course-one__single color-1">
                <div className="course-one__image">
                  <img src={course1} alt="" />
                  
                </div>
                <div className="course-one__content">
                  <a href="#none" className="course-one__category">
                    for schools
                  </a>
                  <h2 className="course-one__title">
                    <a href="/courses/preschool-needs">Preschool needs</a>
                  </h2>
                  <br/>
                  <p>Looking for assistance to establish a new preschool or revamp your existing preschool?</p>
                  <a href="/courses/preschool-needs" className="course-one__link">
                   Know more
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="item">
              <div className="course-one__single color-1">
                <div className="course-one__image">
                  <img src={course2} alt="" />
                  
                </div>
                <div className="course-one__content">
                  <a href="#none" className="course-one__category">
                    for Schools
                  </a>
                  <h2 className="course-one__title">
                    <a href="/courses/preschool-curriculum">Preschool Curriculum</a>
                  </h2>
                  <br/>
                  <p>Essential subjects critical to the development of young children with innovative teaching methods</p>


                  <a href="/courses/preschool-curriculum" className="course-one__link">
                   Know more
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="item">
              <div className="course-one__single color-1">
                <div className="course-one__image">
                  <img src={course3} alt="" />
                  
                </div>
                <div className="course-one__content">
                  <a href="#none" className="course-one__category">
                    for young learners & Teachers
                  </a>
                  <h2 className="course-one__title">
                    <a href="/courses/jolly">Jolly Phonics & Grammar</a>
                  </h2>
                  <p>Fun and child centred approach to teaching literacy through synthetic phonics. </p>


                  <a href="/courses/jolly" className="course-one__link">
                   Know more
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="item">
              <div className="course-one__single color-1">
                <div className="course-one__image">
                  <img src={course4} alt="" />
                  
                </div>
                <div className="course-one__content">
                  <a href="#none" className="course-one__category">
                    for young learners
                  </a>
                  <h2 className="course-one__title">
                    <a href="/courses/edusmarty">EduSmarty Student Kit</a>
                  </h2>
                  <br/>
                  <p>A comprehensive kit to provide your child with the best early childhood education </p>


                  <a href="/courses/edusmarty" className="course-one__link">
                   Know more
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="item">
              <div className="course-one__single color-1">
                <div className="course-one__image">
                  <img src={course5} alt="" />
                  
                </div>
                <div className="course-one__content">
                  <a href="#none" className="course-one__category">
                    for young learners
                  </a>
                  <h2 className="course-one__title">
                    <a href="/courses/handwriting">Handwriting program</a>
                  </h2>
                  <br/>
                  <p>A revolutionary method to facilitate handwriting skills backed by three decades of research. </p>


                  <a href="/courses/handwriting" className="course-one__link">
                   Know more
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="item">
              <div className="course-one__single color-1">
                <div className="course-one__image">
                  <img src={course6} alt="" />
                  
                </div>
                <div className="course-one__content">
                  <a href="#none" className="course-one__category">
                  for Aspiring Teachers
                  </a>
                  <h2 className="course-one__title">
                    <a href="/courses/teacher-training">Nursery Teaching Program</a>
                  </h2>
                  <p>Become a certified Early Childhood Educator and start your career as a qualified teacher. </p>


                  <a href="/courses/teacher-training" className="course-one__link">
                   Know more
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="item">
              <div className="course-one__single color-1">
                <div className="course-one__image">
                  <img src={course7} alt="" />
                  
                </div>
                <div className="course-one__content">
                  <a href="#none" className="course-one__category">
                    for teachers
                  </a>
                  <h2 className="course-one__title">
                    <a href="/courses/professional-development">Professional Development</a>
                  </h2>
                  <p>Get a competitive edge in the arena of early childhood education.</p>


                  <a href="/courses/professional-development" className="course-one__link">
                   Know more
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
      <Footer />
    </Layout>
  );
};

export default CoursesPage;
